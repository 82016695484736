@tailwind base;

/* Your own custom base styles */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Your own custom component styles */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 320px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  right: 30px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float2 {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.floatingImg {
  animation: float 6s ease-in-out infinite;
}
.floatingImg2 {
  animation: float2 6s ease-in-out infinite;
}
